define("ember-google-maps/mixins/process-options", ["exports", "@ember/object/mixin", "@ember/object", "rsvp", "@ember/application/deprecations", "ember-google-maps/utils/options-and-events"], function (_exports, _mixin, _object, _rsvp, _deprecations, _optionsAndEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } /* eslint-disable ember/no-new-mixins */
  /**
   * @class ProcessOptions
   * @module ember-google-maps/mixins/process-options
   * @extends Ember.Mixin
   */
  var _default = _exports.default = _mixin.default.create({
    concatenatedProperties: ['_requiredOptions', '_watchedOptions', '_ignoredAttrs'],
    /**
     * Specify which attributes on the component should be ignored and never
     * considered as a Google Maps option or event.
     *
     * @property _ignoredAttrs
     * @private
     * @type {String[]}
     */
    _ignoredAttrs: ['map', '_internalAPI', 'gMap', 'lat', 'lng', 'events', '_name'],
    /**
     * Required options that are always included in the options object passed to
     * the map component.
     *
     * @property _requiredOptions
     * @private
     * @type {String[]}
     */
    _requiredOptions: [],
    /**
     * Paths to watch for changes. The paths follow the same syntax as the keys
     * for Ember observers and computed properties.
     *
     * @property _watchedOptions
     * @private
     * @type {String[]}
     */
    _watchedOptions: [],
    /**
     * Combined object of options and events used to set and update the options
     * on the map component.
     *
     * @property options
     * @public
     * @return {Object}
     */
    options: (0, _object.computed)('attrs', 'events', '_ignoredAttrs', '_eventAttrs', function () {
      var _getProperties = (0, _object.getProperties)(this, '_ignoredAttrs', '_eventAttrs'),
        _ignoredAttrs = _getProperties._ignoredAttrs,
        _eventAttrs = _getProperties._eventAttrs;
      var ignoredAttrs = [].concat(_toConsumableArray(_ignoredAttrs), _toConsumableArray(_eventAttrs));
      var attrs = Object.keys(this.attrs).filter(function (attr) {
        return ignoredAttrs.indexOf(attr) === -1;
      });
      return (0, _object.getProperties)(this, attrs);
    }),
    _options: (0, _object.computed)('map', 'options', '_requiredOptions', function () {
      var options = (0, _object.get)(this, 'options');
      var _requiredOptions = (0, _object.get)(this, '_requiredOptions');
      var required = (0, _object.getProperties)(this, _requiredOptions);
      return Object.assign({}, required, options);
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      (false && !(false) && (0, _deprecations.deprecate)("\nThe `ProcessOptions` mixin will be removed in the next major version of ember-google-maps. If you need to manually parse component attributes, use the functions provided in `ember-google-maps/utils/options-and-events`.", false, {
        id: 'process-options-mixin-removed',
        until: '4.0'
      }));
      this._watchedListeners = new Map();
      if (!this._eventAttrs) {
        (0, _object.set)(this, '_eventAttrs', []);
      }
      this._isInitialized = false;
      this.isInitialized = (0, _rsvp.defer)();
      this.isInitialized.promise.then(function () {
        return (0, _object.set)(_this, '_isInitialized', true);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._watchedListeners.forEach(function (remove) {
        return remove();
      });
      this._super.apply(this, arguments);
    },
    _registerOptionObservers: function _registerOptionObservers() {
      var _this2 = this;
      var _watchedOptions = (0, _object.get)(this, '_watchedOptions');
      if (_watchedOptions.length === 0) {
        return;
      }
      function update() {
        if (this._isInitialized) {
          this._updateComponent();
        }
      }
      var watched = {};
      _watchedOptions.forEach(function (path) {
        return watched[path] = update.bind(_this2);
      });
      (0, _optionsAndEvents.watch)(this, watched).forEach(function (_ref) {
        var name = _ref.name,
          remove = _ref.remove;
        return _this2._watchedListeners.set(name, remove);
      });
    }
  });
});