define("ember-google-maps/components/g-map/canvas", ["exports", "@ember/component", "@ember/object", "@ember/runloop"], function (_exports, _component, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Renders a canvas div into which the map is inserted.
   *
   * @class Canvas
   * @namespace GMap
   * @module ember-google-maps/components/g-map/canvas
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    // TODO: Remove in Octane version. Use `...attributes` instead.
    computedClasses: (0, _object.computed)('class', 'classNames', function () {
      var classes = ['ember-google-map', (0, _object.get)(this, 'class'), (0, _object.get)(this, 'classNames')];
      return classes.filter(function (x) {
        return x;
      }).join(' ');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      function registerCanvas() {
        var id = (0, _object.get)(this, 'id');
        var canvas = document.getElementById(id);
        this._internalAPI._registerCanvas(canvas);
      }

      // TODO: Remove in Octane version. Splattributes somehow affect the
      // rendering loop, so this is necessary for 2.18.
      (0, _runloop.scheduleOnce)('render', this, registerCanvas);
    }
  });
});