define("ember-google-maps/templates/components/g-map/overlay", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q+0utsS3",
    "block": "[[[41,[48,[30,1]],[[[41,[30,0,[\"_contentContainer\"]],[[[40,[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,3],[[30,0,[\"_contentContainer\"]]],null]]],[]],null]],[]],null]],[\"&default\"],false,[\"if\",\"has-block\",\"in-element\",\"-in-el-null\",\"yield\"]]",
    "moduleName": "ember-google-maps/templates/components/g-map/overlay.hbs",
    "isStrictMode": false
  });
});