define("ember-file-upload/system/uuid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var numbers = new Uint8Array(16);
  function random() {
    crypto.getRandomValues(numbers);
    return numbers;
  }
  function byteToHex(number) {
    return (number + 0x100).toString(16).substr(1);
  }
  function serialize(bytes) {
    var hex = [];
    for (var i = 0, len = bytes.length; i < len; i++) {
      hex[i] = byteToHex(bytes[i]);
    }
    return hex.slice(0, 4).join('') + '-' + hex.slice(4, 6).join('') + '-' + hex.slice(6, 8).join('') + '-' + hex.slice(8, 10).join('') + '-' + hex.slice(10).join('');
  }
  function uuid() {
    var numbers = random();
    numbers[6] = numbers[6] & 0x0f | 0x40;
    numbers[8] = numbers[8] & 0x3f | 0x80;
    return serialize(numbers);
  }
  uuid.short = function () {
    var result = uuid();
    return result.slice(0, 4) + result.slice(5, 6);
  };
  var _default = _exports.default = uuid;
});