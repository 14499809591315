define("ember-google-maps/utils/helpers", ["exports", "@ember/object", "@ember/object/proxy", "@ember/object/promise-proxy-mixin", "rsvp"], function (_exports, _object, _proxy, _promiseProxyMixin, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedPromise = computedPromise;
  _exports.position = position;
  _exports.promisify = promisify;
  function position() {
    var _getProperties = (0, _object.getProperties)(this, 'lat', 'lng'),
      lat = _getProperties.lat,
      lng = _getProperties.lng;
    return lat && lng ? new google.maps.LatLng(lat, lng) : undefined;
  }
  var ObjectPromiseProxy = _proxy.default.extend(_promiseProxyMixin.default);
  function computedPromise() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    var func = args.pop();
    return _object.computed.apply(void 0, args.concat([function () {
      return ObjectPromiseProxy.create({
        promise: func.apply(this)
      });
    }]));
  }
  function promisify(maybePromise) {
    return maybePromise instanceof _rsvp.Promise ? maybePromise : (0, _rsvp.resolve)(maybePromise);
  }
});