define("ember-google-maps/components/g-map/overlay", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/overlay", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers", "@ember/object", "@ember/runloop", "@ember/object/internals", "@ember/debug", "rsvp"], function (_exports, _mapComponent, _overlay, _optionsAndEvents, _helpers, _object, _runloop, _internals, _debug, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var READY = _mapComponent.MapComponentLifecycleEnum.READY;

  /**
   * A wrapper for the google.maps.Overlay class.
   *
   * @class Overlay
   * @namespace GMap
   * @module ember-google-maps/components/g-map/overlay
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    layout: _overlay.default,
    _type: 'overlay',
    position: (0, _object.computed)('lat', 'lng', _helpers.position),
    paneName: 'overlayMouseTarget',
    zIndex: 'auto',
    _targetPane: null,
    _contentId: (0, _object.computed)(function () {
      return "ember-google-maps-overlay-".concat((0, _internals.guidFor)(this));
    }),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([].concat(_toConsumableArray(_optionsAndEvents.ignoredOptions), ['paneName', 'zIndex'])),
    init: function init() {
      this._super(arguments);

      // Remove for 4.0
      (false && (0, _debug.warn)("\nThe `innerContainerStyle` option has been removed. See the docs for examples of how to offset overlays relative to their coordinates.\nhttps://ember-google-maps.sandydoo.me/docs/overlays/", typeof this.innerContainerStyle === 'undefined', {
        id: 'inner-container-style-removed'
      }));
    },
    _addComponent: function _addComponent() {
      var _this = this;
      var isFinishedDrawing = (0, _rsvp.defer)();
      var _contentContainer = document.createElement('div');
      _contentContainer.setAttribute('id', (0, _object.get)(this, '_contentId'));
      (0, _object.set)(this, '_contentContainer', _contentContainer);
      var Overlay = new google.maps.OverlayView();

      // Google Maps runs these setup methods asynchronously. This makes it
      // impossible to schedule all of the DOM operations within one runloop.
      // That's why we provide noops and then run the setup process properly,
      // within a runloop, ourselves.
      Overlay.onAdd = function () {};
      Overlay.onRemove = (0, _runloop.bind)(this, 'onRemove');
      Overlay.draw = function () {
        return (0, _runloop.join)(_this, setupOverlay);
      };
      (0, _object.set)(this, 'mapComponent', Overlay);
      Overlay.setMap(this.map);
      function setupOverlay() {
        var _this2 = this;
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.onAdd();
        (0, _runloop.schedule)('render', this, 'draw');

        // Set the normal draw function.
        Overlay.draw = function () {
          return (0, _runloop.join)(_this2, function () {
            return (0, _runloop.scheduleOnce)('render', _this2, 'draw');
          });
        };
        (0, _runloop.schedule)('afterRender', this, function () {
          return isFinishedDrawing.resolve(Overlay);
        });
      }
      return isFinishedDrawing.promise;
    },
    _didAddComponent: function _didAddComponent(_, options, events) {
      var _this3 = this;
      var payload = {
        map: this.map,
        publicAPI: this.publicAPI
      };
      (0, _optionsAndEvents.addEventListeners)(this._contentContainer, events, payload).forEach(function (_ref) {
        var name = _ref.name,
          remove = _ref.remove;
        return _this3._eventListeners.set(name, remove);
      });
      return (0, _rsvp.resolve)();
    },
    _updateComponent: function _updateComponent() {
      if (this.mapComponentLifecycle === READY) {
        this.mapComponent.draw();
      }
    },
    onAdd: function onAdd() {
      var _this4 = this;
      var panes = this.mapComponent.getPanes();
      (0, _object.set)(this, '_targetPane', panes[this.paneName]);

      // Schedule to append the overlay container to the map pane.
      (0, _runloop.schedule)('render', this, function () {
        if (_this4.isDestroying || _this4.isDestroyed) {
          return;
        }
        _this4._targetPane.appendChild(_this4._contentContainer);
      });
    },
    draw: function draw() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var overlayProjection = this.mapComponent.getProjection(),
        position = (0, _object.get)(this, 'position'),
        point = overlayProjection.fromLatLngToDivPixel(position),
        zIndex = (0, _object.get)(this, 'zIndex');
      this._contentContainer.style.cssText = "\n      position: absolute;\n      left: 0;\n      top: 0;\n      height: 0;\n      z-index: ".concat(zIndex, ";\n      transform: translateX(").concat(point.x, "px) translateY(").concat(point.y, "px);\n    ");
    },
    onRemove: function onRemove() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var parentNode = this._contentContainer.parentNode;
      if (parentNode) {
        parentNode.removeChild(this._contentContainer);
      }
      this._contentContainer = null;
    }
  });
});