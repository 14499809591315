define("ember-google-maps/templates/components/g-map/control", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "UT3mz/l9",
    "block": "[[[41,[30,0,[\"_elementDestination\"]],[[[40,[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,2],[[30,0,[\"_elementDestination\"]]],null]]],[]],null]],[\"&default\"],false,[\"if\",\"in-element\",\"-in-el-null\",\"yield\"]]",
    "moduleName": "ember-google-maps/templates/components/g-map/control.hbs",
    "isStrictMode": false
  });
});