define("ember-file-upload/components/base-component", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    fileQueue: (0, _service.inject)(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.queue) {
        var accept = this.accept,
          multiple = this.multiple,
          disabled = this.disabled,
          onFileAdd = this.onFileAdd;
        (0, _object.setProperties)(this.queue, {
          accept: accept,
          multiple: multiple,
          disabled: disabled,
          onFileAdd: onFileAdd
        });
      }
    },
    queue: (0, _object.computed)('name', {
      get: function get() {
        var queueName = this.name;
        if (queueName != null) {
          var queues = this.fileQueue;
          return queues.find(queueName) || queues.create(queueName);
        } else {
          return undefined;
        }
      }
    })
  });
});