define("ember-google-maps/utils/options-and-events", ["exports", "@ember/object", "@ember/string", "@ember/runloop"], function (_exports, _object, _string, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEventListener = addEventListener;
  _exports.addEventListeners = addEventListeners;
  _exports.ignoredOptions = void 0;
  _exports.isEvent = isEvent;
  _exports.isIgnored = isIgnored;
  _exports.parseOptionsAndEvents = parseOptionsAndEvents;
  _exports.watch = watch;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var ignoredOptions = _exports.ignoredOptions = ['map', 'lat', 'lng', '_internalAPI', 'gMap', 'options', 'events', '_name'];
  function parseOptionsAndEvents() {
    var ignored = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (r) {
      return r;
    };
    var ignoredSet = new Set(ignored);
    return (0, _object.computed)('attrs', function () {
      return callback(parseAttrs(ignoredSet, this.attrs));
    });
  }
  function parseAttrs() {
    var ignored = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Set();
    var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var events = _objectSpread({}, args.events);
    var options = _objectSpread({}, args.options);
    var entries = Object.entries(args);
    entries.forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        k = _ref2[0],
        v = _ref2[1];
      if (isIgnored(k, ignored)) {
        // Pass
      } else if (isEvent(k)) {
        events[k] = v;
      } else {
        options[k] = extractValue(v);
      }
    });
    return {
      events: events,
      options: options
    };
  }
  function isEvent(key) {
    return key.slice(0, 2) === 'on';
  }
  function isIgnored(key, ignored) {
    return ignored.has(key);
  }
  function extractValue(cell) {
    if (cell && cell.constructor && Object.keys(cell).includes('value')) {
      return cell.value;
    }
    return cell;
  }
  function watch(target) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Object.entries(options).map(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
        key = _ref4[0],
        callback = _ref4[1];
      return addObserver(target, key, callback);
    });
  }
  function addObserver(obj, key, callback) {
    var listener = obj.addObserver(key, callback);
    return {
      name: key,
      listener: listener,
      remove: function remove() {
        return obj.removeObserver(key, callback);
      }
    };
  }

  /* Events */

  function addEventListener(target, originalEventName, action) {
    var payload = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var eventName = (0, _string.decamelize)(originalEventName).slice(3);
    function callback(googleEvent) {
      var params = _objectSpread({
        event: window.event,
        googleEvent: googleEvent,
        eventName: eventName,
        target: target
      }, payload);
      (0, _runloop.join)(target, action, params);
    }
    var listener = google.maps.event.addDomListener(target, eventName, callback);
    return {
      name: eventName,
      listener: listener,
      remove: function remove() {
        return listener.remove();
      }
    };
  }

  /**
   * Add event listeners on a target object using the cross-browser event
   * listener library provided by the Google Maps API.
   *
   * @param {Object} target
   * @param {Events} events
   * @param {[Object]} payload = {} An optional object of additional parameters
   *     to include with the event payload.
   * @return {google.maps.MapsEventListener[]} An array of bound event listeners
   *     that should be used to remove the listeners when no longer needed.
   */
  function addEventListeners(target, events) {
    var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return Object.entries(events).map(function (_ref5) {
      var _ref6 = _slicedToArray(_ref5, 2),
        originalEventName = _ref6[0],
        action = _ref6[1];
      return addEventListener(target, originalEventName, action, payload);
    });
  }
});