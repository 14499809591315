define("ember-google-maps/templates/components/g-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q7aP4xBJ",
    "block": "[[[44,[[28,[37,1],null,[[\"canvas\",\"publicAPI\",\"map\",\"_internalAPI\"],[[50,\"g-map/canvas\",0,null,[[\"id\",\"classNames\",\"_internalAPI\"],[[30,0,[\"mapId\"]],[30,0,[\"classNames\"]],[30,0,[\"_internalAPI\"]]]]],[30,0,[\"publicAPI\"]],[30,0,[\"map\"]],[30,0,[\"_internalAPI\"]]]]]],[[[41,[30,1],[[[1,\"\\n\"],[6,[39,4],null,[[\"gMap\",\"map\",\"_internalAPI\"],[[30,0,[\"gMap\"]],[30,1,[\"map\"]],[30,1,[\"_internalAPI\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,5],[[28,[37,6],[[30,0],[30,0,[\"_updateGMap\"]]],null],[30,1],[30,2]],null]],[1,\"\\n\\n\"],[41,[51,[49,[30,4]]],[[[1,\"      \"],[8,[30,1,[\"canvas\"]],[[17,3]],null,null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[18,4,[[30,0,[\"gMap\"]]]],[1,\"\\n\"]],[2]]]]]],[]],null]],[1]]]],[\"gMap\",\"addons\",\"&attrs\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"-private-api/addon-factory\",\"g-map/compute\",\"action\",\"unless\",\"has-block-params\",\"yield\"]]",
    "moduleName": "ember-google-maps/templates/components/g-map.hbs",
    "isStrictMode": false
  });
});