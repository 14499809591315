define("ember-google-maps/templates/components/g-map/marker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "YI3SvDk8",
    "block": "[[[41,[30,0,[\"mapComponent\"]],[[[1,\"  \"],[18,1,[[28,[37,2],null,[[\"infoWindow\"],[[50,\"g-map/info-window\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"target\"],[[33,4],[33,5],[33,6],[30,0,[\"mapComponent\"]]]]]]]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\",\"map\",\"_internalAPI\",\"gMap\"]]",
    "moduleName": "ember-google-maps/templates/components/g-map/marker.hbs",
    "isStrictMode": false
  });
});