define("ember-google-maps/components/g-map/control", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/control", "ember-google-maps/utils/options-and-events", "@ember/object", "rsvp"], function (_exports, _mapComponent, _control, _optionsAndEvents, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  /**
   * @class Control
   * @namespace GMap
   * @module ember-google-maps/components/g-map/control
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    layout: _control.default,
    _type: 'control',
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([].concat(_toConsumableArray(_optionsAndEvents.ignoredOptions), ['index', 'class'])),
    _addComponent: function _addComponent() {
      var _elementDestination = (0, _object.set)(this, '_elementDestination', document.createElement('div'));
      var _getProperties = (0, _object.getProperties)(this, ['map', 'class', 'index']),
        map = _getProperties.map,
        classNames = _getProperties.class,
        index = _getProperties.index;
      if (classNames) {
        _elementDestination.classList.add(classNames);
      }
      if (Number.isInteger(index)) {
        _elementDestination.index = index;
      }
      var controlPosition = google.maps.ControlPosition[(0, _object.get)(this, 'position')];
      map.controls[controlPosition].push(_elementDestination);
      return (0, _rsvp.resolve)((0, _object.set)(this, 'mapComponent', _elementDestination));
    },
    _updateComponent: function _updateComponent() {}
  });
});