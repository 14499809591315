define("ember-wistia/components/wistia-video", ["exports", "ember", "@ember/component", "@ember/object", "@ember/service", "@ember/runloop", "@ember/debug", "ember-wistia/templates/components/wistia-video"], function (_exports, _ember, _component, _object, _service, _runloop, _debug, _wistiaVideo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Logger = _ember.default.Logger;
  var _default = _exports.default = _component.default.extend({
    layout: _wistiaVideo.default,
    matcher: null,
    email: null,
    wistia: (0, _service.inject)(),
    classNames: ['video-wrapper'],
    classNameBindings: ['isPlaying'],
    hideVideo: false,
    videoInitialize: function videoInitialize() {},
    isPlaying: (0, _object.computed)('matcher', function () {
      var wistia = (0, _object.get)(this, 'wistia');
      return wistia.getCurrentlyPlaying() === (0, _object.get)(this, 'matcher');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var wistia = (0, _object.get)(this, 'wistia');
      var email = (0, _object.get)(this, 'email');
      var matcher = (0, _object.get)(this, 'matcher');
      (0, _object.set)(this, 'currentMatcher', matcher);
      wistia.addVideo(matcher, email);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      (false && !((0, _object.get)(this, 'matcher')) && (0, _debug.assert)('You have not passed in a Wistia matcher', (0, _object.get)(this, 'matcher')));
      this._super.apply(this, arguments);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var matcher = (0, _object.get)(this, 'matcher');
      if (matcher !== (0, _object.get)(this, 'currentMatcher')) {
        this._rerenderWistiaVideo(matcher);
      }
      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      var videoInitialize = (0, _object.get)(this, 'videoInitialize');
      var wistia = (0, _object.get)(this, 'wistia');
      var matcher = (0, _object.get)(this, 'matcher');
      wistia.getVideo(matcher).then(function (video) {
        videoInitialize(video, matcher);
      }).catch(function (error) {
        Logger.log(error.msg);
      });
    },
    _rerenderWistiaVideo: function _rerenderWistiaVideo(newMatcher) {
      var _this = this;
      (0, _object.set)(this, 'currentMatcher', newMatcher);
      (0, _object.set)(this, 'hideVideo', true);
      (0, _runloop.next)(function () {
        (0, _object.set)(_this, 'hideVideo', false);
      });
    }
  });
});