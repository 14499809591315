define("ember-moment/helpers/now", ["exports", "moment", "ember-moment/helpers/-base"], function (_exports, _moment, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    compute: function compute() {
      this._super.apply(this, arguments);
      var momentService = this.moment;
      return momentService.moment(_moment.default.now());
    }
  });
});