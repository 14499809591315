define("ember-wistia/services/wistia", ["exports", "ember", "@ember/service", "rsvp", "@ember/object", "@ember/runloop"], function (_exports, _ember, _service, _rsvp, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var Logger = _ember.default.Logger;
  var _default = _exports.default = _service.default.extend({
    currentlyPlaying: null,
    addVideo: function addVideo(matcher) {
      var _this = this;
      var userEmail = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      window._wq = window._wq || [];
      window._wq.push(_defineProperty({}, matcher, function (video) {
        video.bind('pause', function () {
          _this.clearCurrentlyPlaying(video);
        });
        video.bind('play', function () {
          _this.setCurrentlyPlaying(video);
          _this._setAutoPausing(video);
          _this._maybeRecordEmail(video, userEmail);
        });
      }));
    },
    bindVideoEvent: function bindVideoEvent(matcher) {
      for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        rest[_key - 1] = arguments[_key];
      }
      var Wistia = this._wistiaApi();
      (0, _runloop.later)(this, function () {
        var video = Wistia.api(matcher);
        video.bind.apply(video, rest);
      }, 500);
    },
    clearCurrentlyPlaying: function clearCurrentlyPlaying(video) {
      var hashedId = video.hashedId();
      if ((0, _object.get)(this, 'currentlyPlaying') === hashedId) {
        (0, _object.set)(this, 'currentlyPlaying', null);
      }
    },
    getCurrentlyPlaying: function getCurrentlyPlaying() {
      return (0, _object.get)(this, 'currentlyPlaying');
    },
    getVideo: function getVideo(matcher) {
      var Wistia = this._wistiaApi();
      return new _rsvp.Promise(function (resolve, reject) {
        (0, _runloop.later)(this, function () {
          var video = Wistia.api(matcher);
          if (video) {
            resolve(video);
          } else {
            reject({
              msg: 'No video was found'
            });
          }
        }, 500);
      });
    },
    _maybeRecordEmail: function _maybeRecordEmail(video, userEmail) {
      if (userEmail) {
        video.email(userEmail);
      }
    },
    _setAutoPausing: function _setAutoPausing(current) {
      var allVideos = window.Wistia.api.all();
      allVideos.forEach(function (video) {
        if (video.hashedId() !== current.hashedId()) {
          video.pause();
        }
      });
    },
    setCurrentlyPlaying: function setCurrentlyPlaying(video) {
      var hashedId = video.hashedId();
      (0, _object.set)(this, 'currentlyPlaying', hashedId);
    },
    _wistiaApi: function _wistiaApi() {
      if (window.Wistia) {
        return window.Wistia;
      } else {
        return {
          api: function api(matcher) {
            return Logger.log("This API is disabled in testing for: ".concat(matcher));
          }
        };
      }
    }
  });
});