define("ember-google-maps/templates/components/g-map/directions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "yq/pWdm1",
    "block": "[[[18,1,[[28,[37,1],null,[[\"directions\",\"route\",\"waypoint\"],[[33,2],[50,\"g-map/route\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"directions\"],[[33,4],[33,5],[33,6],[33,2]]]],[50,\"g-map/waypoint\",0,null,[[\"_internalAPI\"],[[28,[37,1],null,[[\"_registerComponent\",\"_unregisterComponent\"],[[28,[37,7],[[30,0],[33,8]],null],[28,[37,7],[[30,0],[33,9]],null]]]]]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"directions\",\"component\",\"map\",\"_internalAPI\",\"gMap\",\"action\",\"_registerWaypoint\",\"_unregisterWaypoint\"]]",
    "moduleName": "ember-google-maps/templates/components/g-map/directions.hbs",
    "isStrictMode": false
  });
});