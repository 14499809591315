define("ember-google-maps/templates/components/g-map/autocomplete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "lPV4ggrB",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-google-maps/templates/components/g-map/autocomplete.hbs",
    "isStrictMode": false
  });
});