define("ember-google-maps/templates/components/-private-api/addon-factory", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qCxUHQKB",
    "block": "[[[1,\"\\n\"],[18,1,[[28,[37,1],null,[[\"marker\",\"circle\",\"polyline\",\"infoWindow\",\"overlay\",\"control\",\"autocomplete\",\"directions\",\"route\"],[[50,\"g-map/marker\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[33,3],[33,4],[33,5],\"marker\"]]],[50,\"g-map/circle\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[33,3],[33,4],[33,5],\"circle\"]]],[50,\"g-map/polyline\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[33,3],[33,4],[33,5],\"polyline\"]]],[50,\"g-map/info-window\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[33,3],[33,4],[33,5],\"infoWindow\"]]],[50,\"g-map/overlay\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[33,3],[33,4],[33,5],\"overlay\"]]],[50,\"g-map/control\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[33,3],[33,4],[33,5],\"control\"]]],[50,\"g-map/autocomplete\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[33,3],[33,4],[33,5],\"autocomplete\"]]],[50,\"g-map/directions\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[33,3],[33,4],[33,5],\"directions\"]]],[50,\"g-map/route\",0,null,[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[33,3],[33,4],[33,5],\"route\"]]]]]]]],[1,\"\\n    \"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"map\",\"_internalAPI\",\"gMap\"]]",
    "moduleName": "ember-google-maps/templates/components/-private-api/addon-factory.hbs",
    "isStrictMode": false
  });
});