define("ember-google-maps/components/-private-api/addon-factory", ["exports", "@ember/component", "ember-google-maps/templates/components/-private-api/addon-factory"], function (_exports, _component, _addonFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _addonFactory.default,
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.gMap) {
        this.gMap = {};
      }
    }
  });
});