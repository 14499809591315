define("in-repo-pin-addon/templates/components/g-map-addons/pin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "OhJoWiAp",
    "block": "[[[1,[28,[33,0,[\"marker\"]],null,[[\"lat\",\"lng\"],[\"51.507568\",\"-0.127762\"]]]]],[],false,[\"gMap\"]]",
    "moduleName": "in-repo-pin-addon/templates/components/g-map-addons/pin.hbs",
    "isStrictMode": false
  });
});