define("ember-google-maps/templates/components/g-map/info-window", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "4e2xpUVz",
    "block": "[[[41,[30,0,[\"_targetPane\"]],[[[40,[[[41,[48,[30,1]],[[[1,\"      \"],[18,1,[[33,5]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[34,6]],[1,\"\\n\"]],[]]]],[]],\"%cursor:0%\",[28,[37,2],[[30,0,[\"_targetPane\"]]],null]]],[]],null]],[\"&default\"],false,[\"if\",\"in-element\",\"-in-el-null\",\"has-block\",\"yield\",\"publicAPI\",\"content\"]]",
    "moduleName": "ember-google-maps/templates/components/g-map/info-window.hbs",
    "isStrictMode": false
  });
});