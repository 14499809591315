define("ember-tether/components/ember-tether", ["exports", "@ember/application", "@ember/runloop", "@ember/object", "@ember/utils", "@ember/component", "tether"], function (_exports, _application, _runloop, _object, _utils, _component, _tether2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['ember-tether'],
    classPrefix: 'ember-tether',
    target: null,
    attachment: null,
    targetAttachment: null,
    offset: null,
    targetOffset: null,
    targetModifier: null,
    constraints: null,
    optimizations: null,
    emberTetherConfig: (0, _object.computed)(function () {
      return ((0, _application.getOwner)(this).resolveRegistration('config:environment') || {})['ember-tether'];
    }),
    bodyElement: (0, _object.computed)(function () {
      var config = (0, _object.get)(this, 'emberTetherConfig');
      if (config && config.bodyElementId) {
        return document.getElementById(config.bodyElementId);
      }
    }),
    attributeBindings: ['aria-atomic', 'aria-busy', 'aria-controls', 'aria-current', 'aria-describedby', 'aria-details', 'aria-disabled', 'aria-errormessage', 'aria-flowto', 'aria-haspopup', 'aria-hidden', 'aria-invalid', 'aria-keyshortcuts', 'aria-label', 'aria-labelledby', 'aria-live', 'aria-owns', 'aria-relevant', 'aria-roledescription'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.addTether();
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!this._tether) return;
      var _tether = this._tether,
        element = this.element;
      (0, _runloop.schedule)('render', function () {
        _this.removeElement(element);
        _this.removeTether(_tether);
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.positionTether();
    },
    tetherDidChange: (0, _object.observer)('classPrefix', 'target', 'attachment', 'targetAttachment', 'offset', 'targetOffset', 'targetModifier', 'constraints', 'optimizations', function () {
      this.removeTether(this._tether);
      this.addTether();
    }),
    positionTether: function positionTether() {
      if (this._tether) {
        this._tether.position();
      }
    },
    addTether: function addTether() {
      if ((0, _object.get)(this, '_tetherTarget')) {
        this._tether = new _tether2.default(this._tetherOptions());
      }
    },
    removeTether: function removeTether(tether) {
      if (tether) {
        tether.destroy();
      }
    },
    removeElement: function removeElement(element) {
      if (element.parentNode) {
        element.parentNode.removeChild(element);
      }
    },
    _tetherTarget: (0, _object.computed)('target', function () {
      var t = (0, _object.get)(this, 'target');
      if (t && t.element) {
        t = t.element;
      }
      return t;
    }),
    _tetherOptions: function _tetherOptions() {
      var _this2 = this;
      var options = {
        element: this.element,
        target: (0, _object.get)(this, '_tetherTarget')
      };
      ['classPrefix', 'attachment', 'targetAttachment', 'offset', 'targetOffset', 'targetModifier', 'constraints', 'optimizations', 'bodyElement'].forEach(function (k) {
        var v = (0, _object.get)(_this2, k);
        if (!(0, _utils.isNone)(v)) {
          options[k] = v;
        }
      });
      return options;
    }
  });
});