define("ember-file-upload/components/file-dropzone/component", ["exports", "ember-file-upload/components/base-component", "@ember/runloop", "@ember/object", "@ember/object/computed", "@ember/application", "ember-file-upload/components/file-dropzone/template", "ember-file-upload/system/data-transfer", "ember-file-upload/system/uuid", "ember-file-upload/system/parse-html", "ember-file-upload/system/drag-listener"], function (_exports, _baseComponent, _runloop, _object, _computed, _application, _template, _dataTransfer, _uuid, _parseHtml, _dragListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var DATA_TRANSFER = 'DATA_TRANSFER' + _uuid.default.short();
  var supported = function () {
    return typeof window !== 'undefined' && window.document && 'draggable' in document.createElement('span');
  }();
  var dragListener = new _dragListener.default();

  /**
    `FileDropzone` is a component that will allow users to upload files by
     drag and drop.
  
    ```hbs
    <FileDropzone @name="photos" as |dropzone queue|>
      {{#if dropzone.active}}
        {{#if dropzone.valid}}
          Drop to upload
        {{else}}
          Invalid
        {{/if}}
      {{else if queue.files.length}}
        Uploading {{queue.files.length}} files. ({{queue.progress}}%)
      {{else}}
        <h4>Upload Images</h4>
        <p>
          {{#if dropzone.supported}}
            Drag and drop images onto this area to upload them or
          {{/if}}
          <FileUpload @name="photos"
                      @accept="image/*"
                      @multiple=true
                      @onFileAdd={{this.uploadImage}}>
            <a id="upload-image" tabindex=0>Add an Image.</a>
          </FileUpload>
        </p>
      {{/if}}
    </FileDropzone>
    ```
  
    ```js
    import Controller from '@ember/controller';
  
    export default Ember.Route.extend({
      actions: {
        uploadImage(file) {
         file.upload(URL, options).then((response) => {
            ...
         });
        }
      }
    });
    ```
  
    @class FileDropzone
    @type Ember.Component
    @yield {Hash} dropzone
    @yield {boolean} dropzone.supported
    @yield {boolean} dropzone.active
    @yield {boolean} dropzone.valid
    @yield {Queue} queue
   */
  var _default = _exports.default = _baseComponent.default.extend({
    layout: _template.default,
    supported: supported,
    active: false,
    valid: true,
    /**
      Whether multiple files can be selected when uploading.
      @argument multiple
      @type {boolean}
     */
    multiple: null,
    /**
      The name of the queue to upload the file to.
       @argument name
      @type {string}
      @required
     */
    name: null,
    /**
      If set, disables input and prevents files from being added to the queue
       @argument disabled
      @type {boolean}
      @default false
     */
    disabled: false,
    /**
      A list of MIME types / extensions to be accepted by the input
      @argument accept
      @type {string}
     */
    accept: null,
    /**
      `onFileAdd` is called when a file is selected.
       When multiple files are selected, this function
      is called once for every file that was selected.
       @argument onFileAdd
      @type {function}
      @required
     */
    onFileAdd: null,
    onfileadd: (0, _computed.deprecatingAlias)('onFileAdd', {
      id: 'ember-file-upload.deprecate-non-camel-case-events',
      until: '5.0.0'
    }),
    /**
      `onDragEnter` is called when a file has entered
      the dropzone.
       @argument onDragEnter
      @type {function}
     */
    onDragEnter: null,
    ondragenter: (0, _computed.deprecatingAlias)('onDragEnter', {
      id: 'ember-file-upload.deprecate-non-camel-case-events',
      until: '5.0.0'
    }),
    /**
      `onDragLeave` is called when a file has left
      the dropzone.
       @argument onDragLeave
      @type {function}
     */
    onDragLeave: null,
    ondragleave: (0, _computed.deprecatingAlias)('onDragLeave', {
      id: 'ember-file-upload.deprecate-non-camel-case-events',
      until: '5.0.0'
    }),
    /**
      `onDrop` is called when a file has been dropped.
       @argument onDrop
      @type {function}
     */
    onDrop: null,
    ondrop: (0, _computed.deprecatingAlias)('onDrop', {
      id: 'ember-file-upload.deprecate-non-camel-case-events',
      until: '5.0.0'
    }),
    /**
      Whether users can upload content
      from websites by dragging images from
      another webpage and dropping it into
      your app. The default is `false` to
      prevent cross-site scripting issues.
       @argument allowUploadsFromWebsites
      @type {boolean}
      @default false
     */
    allowUploadsFromWebsites: false,
    /**
      This is the type of cursor that should
      be shown when a drag event happens.
       Corresponds to `dropEffect`.
       This is one of the following:
       - `copy`
      - `move`
      - `link`
       @argument cursor
      @type {string}
      @default null
     */
    cursor: null,
    didInsertElement: function didInsertElement() {
      this._super();
      dragListener.addEventListeners("#".concat(this.elementId), {
        dragenter: (0, _runloop.bind)(this, 'didEnterDropzone'),
        dragleave: (0, _runloop.bind)(this, 'didLeaveDropzone'),
        dragover: (0, _runloop.bind)(this, 'didDragOver'),
        drop: (0, _runloop.bind)(this, 'didDrop')
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      dragListener.removeEventListeners("#".concat(this.elementId));
    },
    isAllowed: function isAllowed() {
      var _getOwner$resolveRegi = (0, _application.getOwner)(this).resolveRegistration('config:environment'),
        environment = _getOwner$resolveRegi.environment;
      return environment === 'test' || this[DATA_TRANSFER].source === 'os' || this.allowUploadsFromWebsites;
    },
    didEnterDropzone: function didEnterDropzone(evt) {
      var dataTransfer = _dataTransfer.default.create({
        queue: this.queue,
        source: evt.source,
        dataTransfer: evt.dataTransfer,
        itemDetails: evt.itemDetails
      });
      this[DATA_TRANSFER] = dataTransfer;
      if (this.isAllowed()) {
        evt.dataTransfer.dropEffect = this.cursor;
        (0, _object.set)(this, 'active', true);
        (0, _object.set)(this, 'valid', dataTransfer.valid);
        if (this.onDragEnter) {
          this.onDragEnter(dataTransfer);
        }
      }
    },
    didLeaveDropzone: function didLeaveDropzone(evt) {
      (0, _object.set)(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
      if (this.isAllowed()) {
        if (evt.dataTransfer) {
          evt.dataTransfer.dropEffect = this.cursor;
        }
        if (this.onDragLeave) {
          this.onDragLeave(this[DATA_TRANSFER]);
          this[DATA_TRANSFER] = null;
        }
        if (this.isDestroyed) {
          return;
        }
        (0, _object.set)(this, 'active', false);
      }
    },
    didDragOver: function didDragOver(evt) {
      (0, _object.set)(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
      if (this.isAllowed()) {
        evt.dataTransfer.dropEffect = this.cursor;
      }
    },
    didDrop: function didDrop(evt) {
      var _this = this;
      (0, _object.set)(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
      if (!this.isAllowed()) {
        evt.dataTransfer.dropEffect = this.cursor;
        this[DATA_TRANSFER] = null;
        return;
      }

      // Testing support for dragging and dropping images
      // from other browser windows
      var url;
      var html = this[DATA_TRANSFER].getData('text/html');
      if (html) {
        var parsedHtml = (0, _parseHtml.default)(html);
        var img = parsedHtml.getElementsByTagName('img')[0];
        if (img) {
          url = img.src;
        }
      }
      if (url == null) {
        url = this[DATA_TRANSFER].getData('text/uri-list');
      }
      if (url) {
        var image = new Image();
        var _url$split$slice = url.split('/').slice(-1),
          _url$split$slice2 = _slicedToArray(_url$split$slice, 1),
          filename = _url$split$slice2[0];
        image.crossOrigin = 'anonymous';
        image.onload = function () {
          var canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);
          if (canvas.toBlob) {
            canvas.toBlob(function (blob) {
              var _this$queue$_addFiles = _this.queue._addFiles([blob], 'web'),
                _this$queue$_addFiles2 = _slicedToArray(_this$queue$_addFiles, 1),
                file = _this$queue$_addFiles2[0];
              (0, _object.set)(file, 'name', filename);
            });
          } else {
            var binStr = atob(canvas.toDataURL().split(',')[1]);
            var len = binStr.length;
            var arr = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            var blob = new Blob([arr], {
              type: 'image/png'
            });
            blob.name = filename;
            var _this$queue$_addFiles3 = _this.queue._addFiles([blob], 'web'),
              _this$queue$_addFiles4 = _slicedToArray(_this$queue$_addFiles3, 1),
              file = _this$queue$_addFiles4[0];
            (0, _object.set)(file, 'name', filename);
          }
        };
        /* eslint-disable no-console */
        image.onerror = function (e) {
          console.log(e);
        };
        /* eslint-enable no-console */
        image.src = url;
      }
      if (this.onDrop) {
        this.onDrop(this[DATA_TRANSFER]);
      }

      // Add file(s) to upload queue.
      (0, _object.set)(this, 'active', false);
      this.queue._addFiles(this[DATA_TRANSFER].files, 'drag-and-drop');
      this[DATA_TRANSFER] = null;
    }
  });
});