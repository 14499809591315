define("ember-simple-auth-token/authenticators/jwt", ["exports", "@ember/object", "@ember/application", "rsvp", "@ember/utils", "@ember/runloop", "ember-simple-auth-token/authenticators/token"], function (_exports, _object, _application, _rsvp, _utils, _runloop, _token) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); } /* global FastBoot */
  var decode = function decode(str) {
    if (typeof atob === 'function') {
      return atob(str);
    } else if ((typeof FastBoot === "undefined" ? "undefined" : _typeof(FastBoot)) === 'object') {
      try {
        var buffer = FastBoot.require('buffer');
        return buffer.Buffer.from(str, 'base64').toString('utf-8');
      } catch (err) {
        throw new Error('buffer must be available for decoding base64 strings in FastBoot. Make sure to add buffer to your fastbootDependencies.');
      }
    } else {
      throw new Error('Neither atob nor the FastBoot global are avaialble. Unable to decode base64 strings.');
    }
  };

  /**
    JWT (JSON Web Token) Authenticator that supports automatic token refresh.
  
    Inspired by [ember-simple-auth-oauth2](https://github.com/simplabs/ember-simple-auth/tree/master/packages/ember-simple-auth-oauth2)
  
    The factory for this authenticator is registered as 'authenticator:jwt` in Ember's container.
  
    @class JWT
    @namespace SimpleAuth.Authenticators
    @module ember-simple-auth-token/authenticators/jwt
    @extends TokenAuthenticator
  */
  var _default = _exports.default = _token.default.extend({
    /**
      @method init
    */
    init: function init() {
      this._super.apply(this, arguments);
      var owner = (0, _application.getOwner)(this);
      var environment = owner ? owner.resolveRegistration('config:environment') || {} : {};
      var config = environment['ember-simple-auth-token'] || {};
      this.tokenDataPropertyName = config.tokenDataPropertyName || 'tokenData';
      this.refreshAccessTokens = config.refreshAccessTokens === false ? false : true;
      this.tokenExpirationInvalidateSession = config.tokenExpirationInvalidateSession === false ? false : true;
      this.serverTokenRefreshEndpoint = config.serverTokenRefreshEndpoint || '/api/token-refresh/';
      this.refreshTokenPropertyName = config.refreshTokenPropertyName || 'refresh_token';
      this.tokenExpireName = config.tokenExpireName || 'exp';
      this.refreshLeeway = config.refreshLeeway || 0;
      this.tokenRefreshInvalidateSessionResponseCodes = config.tokenRefreshInvalidateSessionResponseCodes || [401, 403];
      this.refreshAccessTokenRetryAttempts = config.refreshAccessTokenRetryAttempts || 0;
      this.refreshAccessTokenRetryTimeout = config.refreshAccessTokenRetryTimeout || 1000;
      this.tokenRefreshFailInvalidateSession = config.tokenRefreshFailInvalidateSession === true ? true : false;
    },
    /**
      Restores the session from a set of session properties.
       It will return a resolving promise if one of two conditions is met:
       1) Both `data.token` and `data.expiresAt` are non-empty and `expiresAt` is greater than the calculated `now`.
      2) If `data.token` is non-empty and the decoded token has a key for `tokenExpireName`.
       If `refreshAccessTokens` is true, `scheduleAccessTokenRefresh` will be called and an automatic token refresh will be initiated.
       @method restore
      @param {Object} data Data to restore the session from
      @return {Promise} Promise that when it resolves results in the session being authenticated
    */
    restore: function restore(data) {
      var _this = this;
      var dataObject = _object.default.create(data);
      return new _rsvp.Promise(function (resolve, reject) {
        var now = _this.getCurrentTime();
        var token = dataObject.get(_this.tokenPropertyName);
        var refreshToken = dataObject.get(_this.refreshTokenPropertyName);
        var expiresAt = dataObject.get(_this.tokenExpireName);
        if ((0, _utils.isEmpty)(token)) {
          return reject(new Error('empty token'));
        }
        if ((0, _utils.isEmpty)(expiresAt)) {
          // Fetch the expire time from the token data since `expiresAt` wasn't included in the data object that was passed in.
          var tokenData = _this.getTokenData(token);
          expiresAt = tokenData[_this.tokenExpireName];
          if ((0, _utils.isEmpty)(expiresAt)) {
            return resolve(data);
          }
        }
        if (expiresAt > now) {
          var wait = (expiresAt - now - _this.refreshLeeway) * 1000;
          if (_this.tokenExpirationInvalidateSession) {
            _this.scheduleAccessTokenExpiration(expiresAt);
          }
          if (wait > 0) {
            if (_this.refreshAccessTokens) {
              _this.scheduleAccessTokenRefresh(expiresAt, refreshToken);
            }
            return resolve(data);
          } else if (_this.refreshAccessTokens) {
            return resolve(_this.refreshAccessToken(refreshToken, 0));
          } else {
            return reject(new Error('unable to refresh token'));
          }
        } else {
          // The refresh token might not be expired, we can't test this on the client so attempt to refresh the token. If the server rejects the token the user session will be invalidated
          if (_this.refreshAccessTokens) {
            return resolve(_this.refreshAccessToken(refreshToken, 0));
          } else {
            return reject(new Error('token is expired'));
          }
        }
      });
    },
    /**
      Authenticates the session with the specified `credentials`.
       It will return a resolving promise if it successfully posts a request to the `JWT.serverTokenEndpoint` with the valid credentials.
       An automatic token refresh will be scheduled with the new expiration date from the returned refresh token. That expiration will be merged with the response and the promise resolved.
       @method authenticate
      @param {Object} credentials Credentials to authenticate the session with
      @param {Object} headers Headers to send with the authentication request
      @return {Promise} Promise that resolves when an auth token is successfully acquired from the server and rejects otherwise
    */
    authenticate: function authenticate(credentials, headers) {
      var _this2 = this;
      return this.makeRequest(this.serverTokenEndpoint, credentials, _objectSpread(_objectSpread({}, this.headers), headers)).then(function (response) {
        return _this2.handleAuthResponse(response.json);
      });
    },
    /**
      Schedules a token refresh request to be sent to the backend after a calculated `wait` time has passed.
       If both `token` and `expiresAt` are non-empty, and `expiresAt` minus the optional refres leeway is greater than the calculated `now`, the token refresh will be scheduled through later.
       @method scheduleAccessTokenRefresh
      @param {Integer} expiresAt Timestamp when the token expires
      @param {String} refreshToken Refresh token
    */
    scheduleAccessTokenRefresh: function scheduleAccessTokenRefresh(expiresAt, refreshToken) {
      if (this.refreshAccessTokens) {
        var now = this.getCurrentTime();
        var wait = (expiresAt - now - this.refreshLeeway) * 1000;
        if (!(0, _utils.isEmpty)(refreshToken) && !(0, _utils.isEmpty)(expiresAt)) {
          if (wait > 0) {
            (0, _runloop.cancel)(this._refreshTokenTimeout);
            delete this._refreshTokenTimeout;
            this._refreshTokenTimeout = (0, _runloop.later)(this, this.refreshAccessToken, refreshToken, 0, wait);
          } else if (expiresAt > now) {
            throw new Error('refreshLeeway is too large which is preventing token refresh.');
          }
        }
      }
    },
    /**
      Makes a refresh token request to grab a new authenticated JWT token from the server.
       It will return a resolving promise if a successful POST is made to the `JWT.serverTokenRefreshEndpoint`.
       After the new token is obtained it will schedule the next automatic token refresh based on the new `expiresAt` time.
       The session will be updated via the trigger `sessionDataUpdated`.
       @method refreshAccessToken
      @param {String} refreshToken Refresh token
      @param {Integer} attempts Number of attempts that have been made so far
      @return {Promise} Promise that resolves when an auth token is successfully acquired from the server and rejects otherwise
    */
    refreshAccessToken: function refreshAccessToken(refreshToken, attempts) {
      var _this3 = this;
      var data = this.makeRefreshData(refreshToken);
      return this.makeRequest(this.serverTokenRefreshEndpoint, data, this.headers).then(function (response) {
        var sessionData = _this3.handleAuthResponse(response.json);
        _this3.trigger('sessionDataUpdated', sessionData);
        return sessionData;
      }).catch(function (error) {
        _this3.handleTokenRefreshFail(error.status, refreshToken, attempts);
        return _rsvp.Promise.reject(error);
      });
    },
    /**
      Returns a nested object with the token property name.
      Example:  If `refreshTokenPropertyName` is "data.user.refreshToken", `makeRefreshData` will return {data: {user: {refreshToken: "token goes here"}}}
       @method makeRefreshData
      @param {String} refreshToken Refresh token
      @return {object} Object with the nested property name.
    */
    makeRefreshData: function makeRefreshData(refreshToken) {
      var data = {};
      var nestings = this.refreshTokenPropertyName.split('.');
      var refreshTokenPropertyName = nestings.pop();
      var lastObject = data;
      nestings.forEach(function (nesting) {
        lastObject[nesting] = {};
        lastObject = lastObject[nesting];
      });
      lastObject[refreshTokenPropertyName] = refreshToken;
      return data;
    },
    /**
      Returns the decoded token with accessible returned values.
       @method getTokenData
      @param {String} token Token
      @return {object} Object with properties for the session.
    */
    getTokenData: function getTokenData(token) {
      var payload = token.split('.')[1];
      var decodedPayload = decode(payload.replace(/-/g, '+').replace(/_/g, '/'));
      var tokenData = decodeURIComponent(window.escape(decodedPayload));
      try {
        return JSON.parse(tokenData);
      } catch (error) {
        return tokenData;
      }
    },
    /**
      Cancels any outstanding automatic token refreshes and returns a resolving promise.
       @method invalidate
      @return {Promise} Resolving promise
    */
    invalidate: function invalidate() {
      (0, _runloop.cancel)(this._refreshTokenTimeout);
      delete this._refreshTokenTimeout;
      (0, _runloop.cancel)(this._tokenExpirationTimeout);
      delete this._tokenExpirationTimeout;
      return (0, _rsvp.resolve)();
    },
    /**
      Returns the current time as a timestamp in seconds
       @method getCurrentTime
      @return {Integer} timestamp
    */
    getCurrentTime: function getCurrentTime() {
      return Math.floor(new Date().getTime() / 1000);
    },
    /**
      Handles authentication response from server, and returns session data
       @method handleAuthResponse
      @param {Object} response Response body
    */
    handleAuthResponse: function handleAuthResponse(response) {
      var token = (0, _object.get)(response, this.tokenPropertyName);
      if ((0, _utils.isEmpty)(token)) {
        throw new Error('Token is empty. Please check your backend response.');
      }
      var tokenData = this.getTokenData(token);
      var expiresAt = (0, _object.get)(tokenData, this.tokenExpireName);
      var tokenExpireData = {};
      tokenExpireData[this.tokenExpireName] = expiresAt;
      if (this.tokenExpirationInvalidateSession) {
        this.scheduleAccessTokenExpiration(expiresAt);
      }
      if (this.refreshAccessTokens) {
        var refreshToken = (0, _object.get)(response, this.refreshTokenPropertyName);
        if ((0, _utils.isEmpty)(refreshToken)) {
          throw new Error('Refresh token is empty. Please check your backend response.');
        }
        this.scheduleAccessTokenRefresh(expiresAt, refreshToken);
      }
      return _objectSpread(_objectSpread(_objectSpread({}, response), tokenExpireData), {}, {
        tokenData: tokenData
      });
    },
    /**
      Handles token refresh fail status. If the server response to a token refresh has a status of 401 or 403 then the token in the session will be invalidated and the sessionInvalidated provided by ember-simple-auth will be triggered.
       @method handleTokenRefreshFail
      @param {Integer} refreshStatusCode Status code received when attempting to refresh token
      @param {String} refreshToken Refresh token
      @param {Integer} attempts Number of attempts that have been made so far
    */
    handleTokenRefreshFail: function handleTokenRefreshFail(refreshStatusCode, refreshToken, attempts) {
      var _this4 = this;
      if (this.tokenRefreshInvalidateSessionResponseCodes.includes(refreshStatusCode)) {
        return this.invalidate().then(function () {
          _this4.trigger('sessionDataInvalidated');
        });
      } else if (attempts++ < this.refreshAccessTokenRetryAttempts) {
        (0, _runloop.cancel)(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
        this._refreshTokenTimeout = (0, _runloop.later)(this, this.refreshAccessToken, refreshToken, attempts, this.refreshAccessTokenRetryTimeout);
      } else if (this.tokenRefreshFailInvalidateSession) {
        return this.invalidate().then(function () {
          _this4.trigger('sessionDataInvalidated');
        });
      }
    },
    /**
      Schedules session invalidation at the time token expires.
       @method scheduleAccessTokenExpiration
      @param {Integer} expiresAt Timestamp when the token expires
    */
    scheduleAccessTokenExpiration: function scheduleAccessTokenExpiration(expiresAt) {
      var now = this.getCurrentTime();
      var wait = Math.max((expiresAt - now) * 1000, 0);
      if (!(0, _utils.isEmpty)(expiresAt)) {
        (0, _runloop.cancel)(this._tokenExpirationTimeout);
        delete this._tokenExpirationTimeout;
        this._tokenExpirationTimeout = (0, _runloop.later)(this, this.handleAccessTokenExpiration, wait);
      }
    },
    /**
      Handles access token expiration
       @method handleAccessTokenExpiration
    */
    handleAccessTokenExpiration: function handleAccessTokenExpiration() {
      var _this5 = this;
      return this.invalidate().then(function () {
        _this5.trigger('sessionDataInvalidated');
      });
    }
  });
});