define("ember-wistia/templates/components/wistia-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BWwoUTmV",
    "block": "[[[41,[51,[33,1]],[[[1,\"  \"],[10,0],[15,0,[29,[\"wistia_embed wistia_async_\",[36,2]]]],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"hideVideo\",\"matcher\"]]",
    "moduleName": "ember-wistia/templates/components/wistia-video.hbs",
    "isStrictMode": false
  });
});