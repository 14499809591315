define("ember-google-maps/templates/components/g-map/canvas", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2Pk78JVm",
    "block": "[[[11,0],[16,1,[36,0]],[16,0,[36,1]],[16,5,[36,2]],[17,1],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"id\",\"computedClasses\",\"style\",\"yield\"]]",
    "moduleName": "ember-google-maps/templates/components/g-map/canvas.hbs",
    "isStrictMode": false
  });
});