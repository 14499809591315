define("ember-useragent/instance-initializers/user-agent", ["exports", "@ember/object", "@ember/debug"], function (_exports, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /* global FastBoot */

  function getUserAgent(appInstance) {
    if (typeof FastBoot === 'undefined') {
      var userAgent = (0, _object.get)(window, 'navigator.userAgent');
      (false && !(userAgent) && (0, _debug.assert)('No `userAgent` present in window.navigator', userAgent));
      return userAgent;
    } else {
      var fastboot = appInstance.lookup('service:fastboot');
      var headers = (0, _object.get)(fastboot, 'request.headers');
      var _userAgent = headers.get('user-agent');
      (false && !(_userAgent) && (0, _debug.assert)('No `user-agent` present in FastBoot headers.', _userAgent));
      return _userAgent;
    }
  }
  function initialize(appInstance) {
    var service = appInstance.lookup('service:user-agent');
    (0, _object.set)(service, 'userAgent', getUserAgent(appInstance));
  }
  var _default = _exports.default = {
    initialize: initialize
  };
});