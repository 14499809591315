define("ember-google-maps/components/g-map/autocomplete", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/autocomplete", "ember-google-maps/utils/options-and-events", "@ember/object", "@ember/debug", "rsvp"], function (_exports, _mapComponent, _autocomplete, _optionsAndEvents, _object, _debug, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AutocompleteAPI = AutocompleteAPI;
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function AutocompleteAPI(source) {
    var mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      get place() {
        return source.place;
      }
    });
  }

  /**
   * @class Autocomplete
   * @namespace GMap
   * @module ember-google-maps/components/g-map/autocomplete
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    layout: _autocomplete.default,
    tagName: 'div',
    _type: 'autocomplete',
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([].concat(_toConsumableArray(_optionsAndEvents.ignoredOptions), ['onSearch'])),
    init: function init() {
      this._super.apply(this, arguments);
      this.publicAPI = AutocompleteAPI(this);
    },
    _addComponent: function _addComponent(options) {
      var map = (0, _object.get)(this, 'map');
      var inputElement = this.element.querySelector('input');
      (false && !(inputElement) && (0, _debug.assert)('You must define your own input within the ember-google-maps autocomplete block.', inputElement));
      var autocomplete = new google.maps.places.Autocomplete(inputElement, options);
      (0, _object.set)(this, 'mapComponent', autocomplete);

      // Bias the search results to the current map bounds.
      autocomplete.setBounds(map.getBounds());
      map.addListener('bounds_changed', function () {
        autocomplete.setBounds(map.getBounds());
      });
      autocomplete.addListener('place_changed', this._onSearch.bind(this));
      return (0, _rsvp.resolve)(this.mapComponent);
    },
    _onSearch: function _onSearch() {
      this.place = this.mapComponent.getPlace();
      if (this.place.geometry) {
        var _this$onSearch;
        (_this$onSearch = this.onSearch) === null || _this$onSearch === void 0 ? void 0 : _this$onSearch.call(this, this.publicAPI);
      }
    }
  });
});