define("liquid-fire/components/liquid-if", ["exports", "@ember/component", "liquid-fire/templates/components/liquid-if"], function (_exports, _component, _liquidIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LiquidIf = _component.default.extend({
    positionalParams: ['predicate'],
    // needed for Ember 1.13.[0-5] and 2.0.0-beta.[1-3] support
    layout: _liquidIf.default,
    tagName: '',
    helperName: 'liquid-if'
  });
  LiquidIf.reopenClass({
    positionalParams: ['predicate']
  });
  var _default = _exports.default = LiquidIf;
});